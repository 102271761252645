import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { updateUser } from './_requests'
import Swal from 'sweetalert2'
import { useAuth } from '../../../app/modules/auth'
import { UserModel } from '../../modules/auth/core/_models'


// اسکیمای اعتبارسنجی فیلدهای فروشگاه
const userDetailsSchema = Yup.object().shape({
  phone: Yup.string().required('ورود شماره تلفن الزامی است.'),
  email: Yup.string().required('انتخاب ایمیل الزامی است.'),
  first_name: Yup.string().required('انتخاب نام الزامی است.'),
  last_name: Yup.string().required('ورود نام خانوادگی الزامی است.'),
})



const Setting: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth()


  const formik = useFormik<UserModel>({
    initialValues: {
      id: currentUser?.id || 0, 
      phone: currentUser?.phone || '', 
      email: currentUser?.email || '', 
      first_name: currentUser?.first_name || '', 
      last_name: currentUser?.last_name || '',
      wallet: currentUser?.wallet || 0,
      shopUsername: currentUser?.shopUsername || null,
    },
    validationSchema: userDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        // ارسال داده‌ها به سرور
        await updateUser(values)

        await Swal.fire({
          icon: 'success',
          title: 'موفقیت!',
          text: 'تغییرات با موفقیت ذخیره شد.',
          confirmButtonText: 'بزن بریم!'
        })
      } catch (error) {
        console.error("Error updating shop:", error)

        // استخراج پیام‌های خطا از پاسخ
        const errorResponse = error as { response?: { data?: { message?: string, errors?: Record<string, string[]> } } }
        let errorMessage = 'خطایی در هنگام به‌روزرسانی فروشگاه رخ داد.'

        // بررسی وجود پیام خطای عمومی
        if (errorResponse.response?.data?.message) {
          errorMessage = errorResponse.response.data.message
        }

        // اگر خطاهای خاصی وجود داشته باشد، آن‌ها را به صورت یک رشته نمایش دهیم
        if (errorResponse.response?.data?.errors) {
          const errorDetails = Object.values(errorResponse.response.data.errors).flat()
          errorMessage = errorDetails.join(', ')
        }

        await Swal.fire({
          icon: 'error',
          title: 'خطا!',
          text: errorMessage,
          confirmButtonText: 'بسیار خب!',
        })
      } finally {
        setLoading(false)
      }
    },
  })


  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>


            {/* First Name */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>نام</label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='نام'
                  {...formik.getFieldProps('first_name')}
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.first_name}</div>
                  </div>
                )}
              </div>
            </div>

            {/* Last Name */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>نام خانوادگی</label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='نام خانوادگی'
                  {...formik.getFieldProps('last_name')}
                />
                {formik.touched.last_name && formik.errors.last_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.last_name}</div>
                  </div>
                )}
              </div>
            </div>

            {/* Phone */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>موبایل</label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='موبایل'
                  {...formik.getFieldProps('phone')}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phone}</div>
                  </div>
                )}
              </div>
            </div>

            {/* Email */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>ایمیل</label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='ایمیل'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>










            {/* Submit Button */}
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'ذخیره'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    لطفا صبر کنید... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export { Setting }