import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { ParentCategoryModel } from '../../pages/shop/profile/ProfileModels'
import { getShopCategories } from '../../pages/shop/profile/_requests'
import { getSearches } from './_requests'
import { ProductModel } from './Models'
import { Products } from './components/productCard'

const Index: FC = () => {
  const [categories, setCategories] = useState<ParentCategoryModel[]>([])
  const [latestProducts, setLatestProducts] = useState<ProductModel[]>([])
  const [popularProducts, setPopularProducts] = useState<ProductModel[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getShopCategories()
        setCategories(response.data)
      } catch (error) {
        console.error('Failed to fetch categories:', error)
      }
    }

    const fetchLatestProducts = async () => {
      setLoading(true);
      setError(null);

      try {
        // Directly sending parameters for fetching the latest products
        const response = await getSearches('n=8&s=newest');
        setLatestProducts(response.data);
      } catch (err) {
        setError('خطا در دریافت محصولات جدید');
      } finally {
        setLoading(false);
      }
    }

    const fetchPopularProducts = async () => {
      setLoading(true);
      setError(null);

      try {
        // Directly sending parameters for fetching the most popular products
        const response = await getSearches('n=8&s=mostPopular');
        setPopularProducts(response.data);
      } catch (err) {
        setError('خطا در دریافت محصولات محبوب');
      } finally {
        setLoading(false);
      }
    }

    fetchCategories();
    fetchLatestProducts();
    fetchPopularProducts(); // Fetch popular products
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xxl-12'>
          <div className={`h-md-100`}>
            <div
              className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
              style={{
                backgroundPosition: '100% 50%',
                backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
              }}
            >
              <div>
                <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
                  <span className='me-2'>
                    به دیجی نمایشگاه؛ شبکه اجتماعی صنوف
                    <br />
                    <span className='position-relative d-inline-block text-success'>
                      خوش آمدید!
                      <span className='position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100'></span>
                    </span>
                  </span>
                </div>

                <div className='text-center'>
                  <Link to='/NewShop' className='btn fw-bold btn-primary'>ساخت فروشگاه</Link>
                  <Link to='/search' className='btn fw-bold btn-secondary me-5'>جستجو در بازار</Link>
                </div>
              </div>
              <img
                className='mx-auto h-250px h-lg-300px theme-light-show mb-10'
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/11.png')}
                alt=''
              />
              <img
                className='mx-auto h-250px h-lg-300px theme-dark-show mb-10'
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/11-dark.png')}
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row - نمایش دسته‌بندی‌ها */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {categories.map((category) => (
          <div className='col-md-2' key={category.id}>
            <div className='card h-100'>
              <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                <Link to={`/search?c=${category.id}`} className='text-gray-800 text-hover-primary d-flex flex-column'>
                  <div className='symbol symbol-75px mb-1'>
                    <KTIcon iconName={category.icon} className="landing-cfs" />
                  </div>
                  <div className='fs-6 mb-2'>{category.name}</div>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* end::Row */}

      {/* begin::Row - نمایش جدیدترین محصولات */}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-5'>
        <div className='col-12'>
          <h3 className='text-end mb-5'><Link to='/search?s=newest'>جدیدترین محصولات</Link></h3>
          

          {loading ? (
            <div>در حال بارگذاری...</div>
          ) : error ? (
            <div className="alert alert-danger">{error}</div>
          ) : latestProducts.length === 0 ? (
            <div className="alert alert-info">محصولی جدید یافت نشد!</div>
          ) : (
            <Products products={latestProducts} />
          )}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row - نمایش محبوب ترین محصولات */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 mt-5'>
        <div className='col-12'>
          <h3 className='text-end mb-5'><Link to='/search?s=mostPopular'>محبوب‌ترین محصولات</Link></h3>

          {loading ? (
            <div>در حال بارگذاری...</div>
          ) : error ? (
            <div className="alert alert-danger">{error}</div>
          ) : popularProducts.length === 0 ? (
            <div className="alert alert-info">محصولی محبوب یافت نشد!</div>
          ) : (
            <Products products={popularProducts} />
          )}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-5'>
        <div className='col-6'>
          <h2 className='text-end mb-5'>برای تولید کنندگان</h2>
          <p className='fs-5'>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
          </p>
        </div>
        <div className='col-6 text-center'>
          <img
            className='mx-auto h-200px h-lg-250px theme-light-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/9.png')}
            alt=''
          />
          <img
            className='mx-auto h-200px h-lg-250px theme-dark-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/9-dark.png')}
            alt=''
          />
        </div>
      </div>
      {/* end::Row */}
      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-10'>
        <div className='col-6 text-center'>
          <img
            className='mx-auto h-200px h-lg-250px theme-light-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/10.png')}
            alt=''
          />
          <img
            className='mx-auto h-200px h-lg-250px theme-dark-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/10-dark.png')}
            alt=''
          />
        </div>
        <div className='col-6'>
          <h2 className='text-end mb-5'>برای بازرگانان</h2>
          <p className='fs-5'>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
          </p>
        </div>
      </div>
      {/* end::Row */}
      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-10'>
        <div className='col-6'>
          <h2 className='text-end mb-5'>برای خدمات دهندگان</h2>
          <p className='fs-5'>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
          </p>
        </div>
        <div className='col-6 text-center'>
          <img
            className='mx-auto h-200px h-lg-250px theme-light-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}
            alt=''
          />
          <img
            className='mx-auto h-200px h-lg-250px theme-dark-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/17-dark.png')}
            alt=''
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-10'>
        <div className='col-6 text-center'>
          <img
            className='mx-auto h-200px h-lg-250px theme-light-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/2.png')}
            alt=''
          />
          <img
            className='mx-auto h-200px h-lg-250px theme-dark-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/2-dark.png')}
            alt=''
          />
        </div>
        <div className='col-6'>
          <h2 className='text-end mb-5'>برای عمده فروشان</h2>
          <p className='fs-5'>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
          </p>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-10'>
        <div className='col-6'>
          <h2 className='text-end mb-5'>برای خرده فروشان</h2>
          <p className='fs-5'>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
          </p>
        </div>
        <div className='col-6 text-center'>
          <img
            className='mx-auto h-200px h-lg-250px theme-light-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/8.png')}
            alt=''
          />
          <img
            className='mx-auto h-200px h-lg-250px theme-dark-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/8-dark.png')}
            alt=''
          />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export { Index }
