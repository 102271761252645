import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { register } from '../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import Swal from 'sweetalert2'

const initialValues = {
  firstname: '',
  lastname: '',
  phone: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'حداقل کارکتر مجاز نام 3 کارکتر است')
    .max(50, 'حداکثر کارکتر مجازی نام 50 کارکتر است')
    .required('ورود نام الزامی است'),
  phone: Yup.string()
    .matches(/^09\d{9}$/, 'فرمت شماره موبایل اشتباه است')
    .required('ورود شماره موبایل الزامی است'),
  email: Yup.string()
    .email('فرمت ایمیل اشتباه است')
    .required('ورود ایمیل الزامی است'),
  lastname: Yup.string()
    .min(3, 'حداقل کارکتر مجاز نام خانوادگی 3 کارکتر است')
    .max(50, 'حداکثر کارکتر مجاز نام خانوادگی 50 کارکتر است')
    .required('ورود نام خانوادگی الزامی است'),
  password: Yup.string()
    .min(8, 'حداقل کارکتر مجاز رمزعبور 8 کارکتر است')
    .max(50, 'حداکثر کارکتر مجاز رمزعبور 50 کارکتر است')
    .required('ورود رمزعبور الزامی است'),
  changepassword: Yup.string()
    .min(8, 'حداقل کارکتر مجاز تکرار رمزعبور 8 کارکتر است')
    .max(50, 'حداکثر کارکتر مجاز تکرار رمزعبور 50 کارکتر است')
    .required('ورود تکرار رمزعبور الزامی است')
    .oneOf([Yup.ref('password')], "رمزعبور و تکرار رمزعبور مطابقت ندارد"),
  acceptTerms: Yup.bool().required('پذیرش قوانین الزامی است'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await register(
          values.phone,
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          values.changepassword
        )
        if (auth.status) {
          Swal.fire({
            title: 'عضویت موفق',
            text: 'حساب شما با موفقیت ایجاد شد.',
            icon: 'success',
            timer: 2000,
            confirmButtonText: 'بزن بریم!',
          })
          navigate('/auth/login', { replace: true })
        } else {
          if (auth.error == 1) {
            Swal.fire({
              title: 'اطلاعات ناقص',
              text: 'تکمیل تمام فیلدها الزامی است.',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          } else if (auth.error == 2) {
            Swal.fire({
              title: 'موبایل نامعتبر',
              text: 'لطفا یک شماره موبایل معتبر وارد کنید.',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          } else if (auth.error == 3) {
            Swal.fire({
              title: 'رمز عبور اشتباه',
              text: 'رمزعبور باید حداقل 8 کاراکتر و ترکیبی از حروف، اعداد و نمادها باشد.',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          } else if (auth.error == 4) {
            Swal.fire({
              title: 'عدم تطابق رمزعبورها',
              text: 'رمزعبور و تکرار رمز عبور مطابقت ندارد.',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          } else if (auth.error == 5) {
            Swal.fire({
              title: 'موبایل تکراری',
              text: 'این شماره موبایل قبلا ثبت شده است.',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          } else if (auth.error == 6) {
            Swal.fire({
              title: 'ایمیل تکراری',
              text: 'این ایمیل قبلا ثبت شده است.',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          } else {
            Swal.fire({
              title: 'ارور ناشناخته',
              text: '',
              icon: 'error',
              confirmButtonText: 'بسیار خب!',
            })
          }
        }
        setLoading(false)
        setSubmitting(false)
      } catch (error) {
        console.error(error)
        setStatus('اطلاعات عضویت نادرست است')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>عضویت</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>با شماره موبایل</span>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>نام</label>
        <input
          placeholder='نام'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>نام خانوادگی</label>
        <input
          placeholder='نام خانوادگی'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lastname && formik.errors.lastname,
            },
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname,
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Phone */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>موبایل</label>
        <input
          placeholder='شماره موبایل'
          type='phone'
          autoComplete='off'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.phone && formik.errors.phone },
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>ایمیل</label>
        <input
          placeholder='ایمیل'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>رمزعبور</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='رمزعبور'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          رمزعبور باید حداقل 8 کاراکتر و ترکیبی از حروف، اعداد و نمادها باشد.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>تکرار رمزعبور</label>
        <input
          type='password'
          placeholder='تکرار رمزعبور'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            تمام <a
              href='/terms'
              target='_blank'
              className='link-primary'
            >قوانین و ضوابط</a> را می‌پذیرم.
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>عضویت</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              لطفا صبر کنید...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            انصراف
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
